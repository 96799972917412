export default ({ id, load, where, page, perpage, time_start, time_end }) => {
    let query = '?'
    if (id) {
        query += `id=${id}`
    }
    if (load) {
        query += `&load=${load}`
    }
    if (where) {
        query += `&where=${where}`
    }
    if (page) {
        query += `&page=${page}`
    }
    if (perpage) {
        query += `&perpage=${perpage}`
    }
    if (time_start) {
        query += `&time_start=${time_start}`
    }
    if (time_end) {
        query += `&time_end=${time_end}`
    }
    return query
}