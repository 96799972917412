import axios from 'axios';

// Interceptor for responses
const responseInterceptor = (response) => {
  switch(response.status) {
      case 200: 
          // console.log({ path: response.config.url, status: response.status});
          break;
      // any other cases
      default:
        console.log(response);
  }

  return response;
}

// interceptor to catch errors
const errorInterceptor = error => {

  // check if it's a server error
  if (!error.response) {
    // notify.warn('Network/Server error');
    return Promise.reject(error);
  }

  // all the error responses
  switch(error.response.status) {
      case 400:
          console.error(error.response.status, error.message);
          // notify.warn('Nothing to display','Data Not Found');
          break;

      default:
          console.error(error.response.status, error.message);
          // localStorage.clear();
          // location.reload();
          // notify.error('Server Error');

  }
  return Promise.reject(error);
}

// ===========================================================================

export default ({ requiresAuth }) => {
  const tempToken = ``
  const token = localStorage.getItem('token') || tempToken
  var options = {
    // baseURL: 'http://localhost:3000',
    // baseURL: 'http://192.168.2.51:3000',
    baseURL: 'https://api.employyim.com'
  }
  
  if (requiresAuth) {
    options.headers = { 
      Authorization: `Bearer ${token}`
    }
  }

  const instance = axios.create(options);
  instance.interceptors.response.use(responseInterceptor, errorInterceptor);

  return instance;
};