
import httpClient from './httpClient';
import queryBuilder from './queryBuilder'

export default {
  async import_users(data) {
    const res = await httpClient({ requiresAuth: true }).post('/import-users', data)

    return res.data
  },
  async export_pdpa(id) {
    const res = await httpClient({ requiresAuth: true }).get(`/export/pdpa?id=${id}`)

    return res.data
  },
  async export_order(id) {
    const res = await httpClient({ requiresAuth: true }).get(`/export/order?id=${id}`)

    return res.data
  },
  async get_order({ time_start, time_end, page, perpage, where }) {
    const res = await httpClient({ requiresAuth: true }).get(`/order${queryBuilder({ where, page, perpage, time_start, time_end })}`)

    return res.data
  },
  async get_payback({ time_start, time_end, page, perpage, where }) {
    const res = await httpClient({ requiresAuth: true }).get(`/payback${queryBuilder({ where, page, perpage, time_start, time_end })}`)

    return res.data
  },
  async login ({ username, password }) {
    const res = await httpClient({ requiresAuth: false }).post('/login', { username, password })
    
    return res.data
  },
  async profile () {
    const res = await httpClient({ requiresAuth: true }).get('/profile')

    return res.data
  },
  async get_model (modelname, { id, load, where, page, perpage }) {
    const res = await httpClient({ requiresAuth: true }).get(`/model/${modelname}${queryBuilder({ id, load, where, page, perpage })}`)

    return res.data
  },
  async create_model (modelname, { data }) {
    const res = await httpClient({ requiresAuth: true }).post(`/model/${modelname}`, { data })

    return res.data
  },
  async update_model (modelname, id, { data }) {
    const res = await httpClient({ requiresAuth: true }).put(`/model/${modelname}?id=${id}`, { data })

    return res.data
  },
  async delete_model (modelname, id) {
    const res = await httpClient({ requiresAuth: true }).delete(`/model/${modelname}?id=${id}`)

    return res.data
  }
}